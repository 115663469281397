/**
 * Users Routes
 */
/* eslint-disable */
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

// async components
import {
    AsyncChecklistManagementNewComponent,
    AsyncChecklistNewComponent
} from 'Components/AsyncComponent/AsyncComponent';

const Forms = ({ match }) => (
    <div className="content-wrapper">
        <Switch>
            <Route path={`${match.url}/management`} component={AsyncChecklistManagementNewComponent} />
            <Route path={`${match.url}/new/:id?`} component={AsyncChecklistNewComponent} />
        </Switch>
    </div>
);

export default Forms;
