/**
 * App Header
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Link } from 'react-router-dom';
import screenfull from 'screenfull';
import Tooltip from '@material-ui/core/Tooltip';
import MenuIcon from '@material-ui/icons/Menu';
import { withRouter } from 'react-router-dom';

// actions
import { collapsedSidebarAction } from 'Actions';

// helpers
import { getAppLayout } from "Helpers/helpers";

// components
import Notifications from './Notifications';
import ChatSidebar from './ChatSidebar';
import DashboardOverlay from '../DashboardOverlay/DashboardOverlay';
import LanguageProvider from './LanguageProvider';
import SearchForm from './SearchForm';
import QuickLinks from './QuickLinks';
import MobileSearchForm from './MobileSearchForm';
import Cart from './Cart';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Switch from '@material-ui/core/Switch';
import { Form, FormGroup, Label, Input, FormFeedback } from 'reactstrap';


import {
	toggleSidebarImage,
	setSidebarBgImageAction,
	miniSidebarAction,
	darkModeAction,
	boxLayoutAction,
	rtlLayoutAction,
	changeThemeColor,
	toggleDarkSidebar
} from 'Actions';


// intl messages
import IntlMessages from 'Util/IntlMessages';

class Header extends Component {
       
	state = {
		customizer: false,
		isMobileSearchFormVisible: false
	}

	// function to change the state of collapsed sidebar
	onToggleNavCollapsed = (event) => {
		const val = !this.props.navCollapsed;
		this.props.collapsedSidebarAction(val);
	}

	// open dashboard overlay
	openDashboardOverlay(e) {
		var el = document.getElementsByClassName('dashboard-overlay')[0];
		el.classList.toggle("d-none");
		el.classList.toggle("show");
		if (el.classList.contains('show')) {
			document.body.style.overflow = "hidden";
		}
		else {
			document.body.style.overflow = "";
		}
		e.preventDefault();
	}

	// close dashboard overlay
	closeDashboardOverlay() {
		var e = document.getElementsByClassName('dashboard-overlay')[0];
		e.classList.remove('show');
		e.classList.add('d-none');
		document.body.style.overflow = "";
	}

	// toggle screen full
	toggleScreenFull() {
		screenfull.toggle();
	}
        
        /**
	 * Dark Mode Event Hanlder
	 * Use To Enable Dark Mode
	 * @param {*object} event
	*/
	darkModeHanlder(isTrue) {
		if (isTrue) {
			document.body.classList.add("dark-mode");
		}
		else {
			document.body.classList.remove("dark-mode");
		}
		this.props.darkModeAction(isTrue);
	}

	// mobile search form
	openMobileSearchForm() {
		this.setState({ isMobileSearchFormVisible: true });
	}
        
        refreshSistem(){

            if (window.localStorage) {
              // Limpia el almacenamiento local
              //window.localStorage.clear();
            }
            if (window.sessionStorage) {
              // Limpia el almacenamiento de sesión
             // window.sessionStorage.clear();
            }
            //console.log("asaa");
            //console.log(window.caches);
            if (window.caches) {
              // Limpia el caché del navegador
              window.caches.keys().then(cacheNames => {
                cacheNames.forEach(cacheName => {
                    //console.log(cacheName);
                  window.caches.delete(cacheName);
                });
              });
            }
            // Recarga la página para aplicar los cambios
            window.location.reload(true);    
        }

	render() {  
		const { isMobileSearchFormVisible } = this.state;
		const { horizontalMenu, agencyMenu, location, darkMode } = this.props;
              
		return (
			<AppBar position="static" className="rct-header">
				<Toolbar className="d-flex justify-content-between w-100 pl-0">
					<div className="d-flex align-items-center">
						{(horizontalMenu || agencyMenu) &&
							<div className="site-logo">
								<Link to="/" className="logo-mini">
									<img src={require('Assets/img/benu.png').default} className="mr-45" alt width="80" height="65" />
								</Link>
								<Link to="/" className="logo-normal">
									Transbenumapu
								</Link>
							</div>
						}
						{!agencyMenu &&
							<ul className="list-inline mb-0 navbar-left">
								{!horizontalMenu ?
									<li className="list-inline-item" onClick={(e) => this.onToggleNavCollapsed(e)}>
										<Tooltip title="" placement="bottom">
											<IconButton color="inherit" mini="true" aria-label="Menu" className="humburger p-0">
												<MenuIcon />
											</IconButton>
										</Tooltip>
									</li> :
									<li className="list-inline-item">
										<Tooltip title="" placement="bottom">
											<IconButton color="inherit" aria-label="Menu" className="humburger p-0" component={Link} to="/">
												<i className="ti-layout-sidebar-left"></i>
											</IconButton>
										</Tooltip>
									</li>
								}
								
								
							</ul>
						}
					</div>
					<ul className="navbar-right list-inline mb-0">
                                        
                                                <li className="list-inline-item">
						  
                                                    <Tooltip title="Refrescar Sistema"  placement="bottom">
                                                  
                                                        <IconButton aria-label="settings" onClick={() => this.refreshSistem()}>
                                                                <i className="zmdi zmdi-refresh"></i>
                                                        </IconButton>
                                                     </Tooltip>
                                               
						</li>

                                                <li className="list-inline-item">
						  
                                                    <Tooltip title={(darkMode === 1 || darkMode === true) ? "Desactivar modo oscuro" : "Activar modo oscuro" }  placement="bottom">
                                                  
                                                        <Switch name="darkMode" color="primary" id="darkMode" checked={(darkMode === 1 || darkMode === true) ? true : false } 
                                                        onChange={(e) => this.darkModeHanlder(e.target.checked)} aria-label="darkMode" className="switch-btn" />
                                                     </Tooltip>
                                               
						</li>
						<li className="list-inline-item">
							<Tooltip title="Pantalla completa" placement="bottom">
								<IconButton aria-label="settings" onClick={() => this.toggleScreenFull()}>
									<i className="zmdi zmdi-crop-free"></i>
								</IconButton>
							</Tooltip>
						</li>
                                                
                                                
                                                
					</ul>
					<Drawer
						anchor={'right'}
						open={this.state.customizer}
						onClose={() => this.setState({ customizer: false })}
					>
						<ChatSidebar />
					</Drawer>
				</Toolbar>
				<DashboardOverlay
					onClose={() => this.closeDashboardOverlay()}
				/>
			</AppBar>
		);
	}
}

// map state to props
const mapStateToProps = ({ settings }) => {
	return settings;
};

export default withRouter(connect(mapStateToProps, {
	collapsedSidebarAction,
        toggleSidebarImage,
	setSidebarBgImageAction,
	miniSidebarAction,
	darkModeAction,
	boxLayoutAction,
	rtlLayoutAction,
	changeThemeColor,
	toggleDarkSidebar
})(Header));
