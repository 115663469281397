// sidebar nav links
export default {
   category1: [
     /* {
         "menu_title": "sidebar.dashboard",
         "menu_icon": "zmdi zmdi-view-dashboard",
         "type_multi": null,
         "rol" : ['administrador'],
         "child_routes": [
            {
               "menu_title": "sidebar.ecommerce",
               "new_item": false,
               "path": "/app/dashboard/ecommerce",
               "rol" : ['administrador']
            },
            {
               "menu_title": "Prueba",
               "new_item": true,
               "path": "/app/users/prueba",
               "rol" : ['administrador']
            }
         ]
      }*/
      {    
         "menu_title": "sidebar.dashboard",
         "menu_icon": "zmdi zmdi-view-dashboard",
         "type_multi": null,
         "rol" : ['supervisor','administrador','empleado'],
         "path": "/app/dashboard"
      }
   ],
  
    category3: [
      {
         "menu_title": "sidebar.users",
         "menu_icon": "zmdi zmdi-accounts",
         "type_multi": null,
         "new_item": false,
         "rol" : ['administrador'],
         "child_routes": [
            {
               "menu_title": "sidebar.userManagement",
               "new_item": false,
               "path": "/app/users/user-management",
               "rol" : ['administrador']
           }
         ]
         
      }
   ],

   category6: [
      {
         "menu_title": "sidebar.checklist",
         "menu_icon": "zmdi zmdi-file-text",
         "type_multi": null,
         "new_item": false,
         "rol" : ['supervisor','administrador','empleado'],
         "child_routes": [
            {
               "menu_title": "sidebar.checklistManagement",
               "new_item": false,
               "path": "/app/checklist/management",
               "rol" : ['supervisor','administrador']
            },
            {
               "menu_title": "sidebar.newChecklist",
               "new_item": false,
               "path": "/app/checklist/new",
               "rol" : ['empleado','administrador','supervisor']
            },
            {
               "menu_title": "sidebar.newChecklist2",
               "new_item": false,
               "path": "/app/checklist/new2",
               "rol" : ['empleado','administrador','supervisor']
            }
         ]
      }
   ],
    category7: [
      {
         "menu_title": "Logs",
         "menu_icon": "zmdi zmdi-mouse",
         "type_multi": null,
         "rol" : ['administrador'],
         "path": "/app/logs",
         "new_item": false
      }
   ]
}
