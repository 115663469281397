/**
 * Notification Component
 */
import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import Button from '@material-ui/core/Button';
import { Badge } from 'reactstrap';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

// api
import api from 'Api';

// intl messages
import IntlMessages from 'Util/IntlMessages';

class Notifications extends Component {
    constructor(props) {
        super(props);
        this.dropdownToggle = React.createRef();
      }
   state = {
      notifications: null,
      totalFichas :0,
      notificacionesFichas :null,
      isOpen:true,
      abierto:true
   }
   
   handleClick = () => {
    // Access the current property of the ref and simulate a click
    if (this.dropdownToggle.current) {
      this.dropdownToggle.current.toggle();
    }
  };
   
  

   componentDidMount() {
      this.getNotifications();
   }
  
   getNotifications() {

        if (localStorage.getItem("user_id") !== null) {
            let rol = localStorage.getItem('rol');
            if(rol === 'administrador' || rol === 'supervisor' ){
                axios.get(`fichas/vencidas/`+localStorage.getItem("user_id"))
                    .then((res) => { 
                            this.setState({ totalFichas: res.data.total, notificacionesFichas:res.data });
                            if(res.data.total > 0){
                                this.setState({ isOpen:false });
                            }
                        })
                    .catch(error => {
                       console.log(error);
                 });

            }                    
      }
 
   }

   render() {
      const { notifications, totalFichas, notificacionesFichas, isOpen, abierto } = this.state;
      
      const handleToggle = (nextIsOpen, event, metadata) => {
        this.setState({isOpen:true});
      };

      return (
         <UncontrolledDropdown ref={this.dropdownToggle}   onToggle={handleToggle}  nav className="list-inline-item notification-dropdown">
            <DropdownToggle  nav className="p-0">
               <Tooltip title="Notificaciones" placement="bottom">
                  <IconButton className={`${!isOpen ? 'shake' : ''}`}  aria-label="bell">
                     <i className={`${!isOpen ? 'zmdi zmdi-notifications-active' : 'zmdi zmdi-notifications'}`}></i>
                     {(notificacionesFichas && !isOpen) &&
                        <Badge color="danger" className="badge-xs badge-top-right rct-notify">{notificacionesFichas.total}</Badge>
                     }
                  </IconButton>
               </Tooltip>
            </DropdownToggle>
            <DropdownMenu right>
               <div className="dropdown-content">
                  <div className="dropdown-top d-flex justify-content-between rounded-top bg-primary">
                     <span className="text-white font-weight-bold">
                        <IntlMessages id="widgets.recentNotifications" />
                     </span>
                     <Badge color="warning">{totalFichas} Nueva(s)</Badge>
                  </div>
                  <Scrollbars className="rct-scroll" autoHeight autoHeightMin={100} autoHeightMax={280}>
                     <ul className="list-unstyled dropdown-list">
                        {notificacionesFichas && notificacionesFichas.vencidos && notificacionesFichas.vencidos.map((notification, key) => (
                           <li key={key}>
                              <div className="media">
                                 <div className="mr-10">
                                    <img src={require('Assets/avatars/no-photo.png').default} alt="user profile" className="media-object rounded-circle" width="50" height="50" />
                                 </div>
                                 <div className="media-body pt-5">
                                    <div className="d-flex justify-content-between">
                                       <h5 className="mb-5 text-primary">{notification.usuario}</h5>
                                       <span className="text-muted fs-12">{notification.vence}</span>
                                    </div>
                                    <span className="text-muted fs-12 d-block">Doc. Vencido: {notification.documento}</span>
                                    {/*<Button className="btn-xs mr-10">
                                       <i className="zmdi zmdi-mail-reply mr-2"></i> <IntlMessages id="button.reply" />
                                    </Button>
                                    <Button className="btn-xs">
                                       <i className="zmdi zmdi-thumb-up mr-2"></i> <IntlMessages id="button.like" />
                                    </Button>*/}
                                 </div>
                              </div>
                           </li>
                        ))}
                        {notificacionesFichas && notificacionesFichas.por_vencer && notificacionesFichas.por_vencer.map((notification, key2) => (
                           <li key={key2}>
                              <div className="media">
                                 <div className="mr-10">
                                    <img src={require('Assets/avatars/no-photo.png').default} alt="user profile" className="media-object rounded-circle" width="50" height="50" />
                                 </div>
                                 <div className="media-body pt-5">
                                    <div className="d-flex justify-content-between">
                                       <h5 className="mb-5 text-primary">{notification.usuario}</h5>
                                       <span className="text-muted fs-12">{notification.por_vencer}</span>
                                    </div>
                                    <span className="text-muted fs-12 d-block">Doc. Por Vencer: {notification.documento}</span>
                                    {/*<Button className="btn-xs mr-10">
                                       <i className="zmdi zmdi-mail-reply mr-2"></i> <IntlMessages id="button.reply" />
                                    </Button>
                                    <Button className="btn-xs">
                                       <i className="zmdi zmdi-thumb-up mr-2"></i> <IntlMessages id="button.like" />
                                    </Button>*/}
                                 </div>
                              </div>
                           </li>
                        ))}
                     </ul>
                  </Scrollbars>
               </div>
               <div className="dropdown-foot p-2 bg-white rounded-bottom">
                  <Button
                     variant="contained"
                     onClick={this.handleClick}
                     color="primary"
                     className="mr-10 btn-xs bg-primary"
                  >
                     Cerrar
                  </Button>
               </div>
            </DropdownMenu>
         </UncontrolledDropdown>
      );
   }
}

export default Notifications;
