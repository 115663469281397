import appLocaleData from 'react-intl/locale-data/hu';
import saMessages from '../locales/hu_HU';

const huLang = {
   messages: {
      ...saMessages
   },
   locale: 'hu-hu',
   data: appLocaleData
};

export default huLang;