    /**
 * Document Management Page
 */
import React, { Component, Fragment } from 'react';
import { Helmet } from "react-helmet";
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import MUIDataTable from "mui-datatables";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from "@material-ui/icons/Delete";
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import {validate as Validate, clean, format as Format, getCheckDigit} from 'rut.js';
import { DateTimePicker, DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { es } from "date-fns/locale";
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import esLocale from "date-fns/locale/es";

import {
	Pagination,
	PaginationItem,
	PaginationLink,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Badge
} from 'reactstrap';
import { NotificationManager } from 'react-notifications';
import Avatar from '@material-ui/core/Avatar';
import { format, parseISO  } from 'date-fns';
// api
import api from 'Api';
// page title bar
import PageTitleBar from 'Components/PageTitleBar/PageTitleBar';

// intl messages
import IntlMessages from 'Util/IntlMessages';

// rct card box
import RctCollapsibleCard from 'Components/RctCollapsibleCard/RctCollapsibleCard';

// rct section loader
import RctSectionLoader from 'Components/RctSectionLoader/RctSectionLoader';

import { TableCell } from '@material-ui/core'

const oldRender = TableCell.render;

TableCell.render = function (...args) {
    const [props, ...otherArgs] = args
    if (typeof props === 'object' && props && 'isEmpty' in props) {
        const { isEmpty, ...propsWithoutEmpty } = props
        return oldRender.apply(this, [propsWithoutEmpty, ...otherArgs])
    } else {
        return oldRender.apply(this, args)
    }
}

export default class Logs extends React.Component {
        
        state = {
		all: false,
		loading: true, // loading activity
                logs: null,
                maxDateNew : new Date().setFullYear(new Date().getFullYear() + 1),
                minDateNew: new Date(new Date().getFullYear() - 4, new Date().getMonth(), new Date().getDate()),
                selectedDate: new Date(),
	}
             
        handleDateChange = (date) => {
            this.setState({ selectedDate: date, loading: true  });
            var month = (date.getMonth() + 1).toString().padStart(2, "0"); 
            var year = date.getUTCFullYear();
            
            axios.get(`/logs/`+month+'-'+year)
                .then(
                    (res) => {
                        
                        var result = [];
                        for(var i in res.data){
                            var localArray = [];
                            localArray.push(res.data[i].rut);
                            localArray.push(res.data[i].name);
                            localArray.push(res.data[i].descripcion);
                            localArray.push(res.data[i].modulo);
                            localArray.push(res.data[i].created_at);
                            localArray.push(res.data[i].device);
                            localArray.push(res.data[i]);
                            result.push(localArray);
                           }
                        this.setState({  loading: false, dataTable: result, logs: res.data, rowsSelected: [] });
                    })
                .catch(error => {
                   this.setState({ loading: false });
                    if (error.response) {
                        NotificationManager.error(error.response.data.message);
                      } else if (error.request) {
                        NotificationManager.error(error.request);
                      } else {
                        NotificationManager.error(error.message);
                      }
             });
        };
        
        componentWillMount(){

            const { location, history} = this.props;
            axios.get('/checkloggin', {}).then((response) => {
              if(!response.data){
                  if (localStorage.getItem("user_id") !== null) {
                        localStorage.removeItem('user_id');
                        localStorage.removeItem('name');
                        localStorage.removeItem('email');
                        localStorage.removeItem('rut');
                        localStorage.removeItem('rol');
                        history.push('/signin');
                        NotificationManager.error('¡Se ha perdido la sesión!');
                    }
              } else {
                  let rol = response.data.rol;
                  let id = response.data.id;
                  console.log(id);
                    if(rol != 'administrador'){
                          history.push('/session/404');
                    }
                  this.Init();
              }
            }, (error) => {
                console.log(error);
                
            });
        }
        
        Init(){
            const { selectedDate } = this.state;

            var month = (selectedDate.getMonth() + 1).toString().padStart(2, "0"); 
            var year = selectedDate.getUTCFullYear();
            
            axios.get(`/logs/`+month+'-'+year)
                .then(
                    (res) => {
                        
                        var result = [];
                        for(var i in res.data){
                            var localArray = [];
                            localArray.push(res.data[i].rut);
                            localArray.push(res.data[i].name);
                            localArray.push(res.data[i].descripcion);
                            localArray.push(res.data[i].modulo);
                            localArray.push(res.data[i].created_at);
                            localArray.push(res.data[i].device);
                            localArray.push(res.data[i]);
                            result.push(localArray);
                           }

                        this.setState({ logs: res.data, loading: false, dataTable: result, rowsSelected: [] });
                    })
                .catch(error => {
                   NotificationManager.error(error.message);
                   this.setState({ loading: false });
             })
          }
          
         
	/**
	 * On Reload
	 */
        onReload(e) {
            
            const { selectedDate } = this.state; 
            this.setState({ loading: true });
            var month = (selectedDate.getMonth() + 1).toString().padStart(2, "0"); 
            var year = selectedDate.getUTCFullYear();
            
            axios.get(`/logs/`+month+'-'+year)
                .then(
                    (res) => {
                        
                        var result = [];
                        for(var i in res.data){
                            var localArray = [];
                            localArray.push(res.data[i].rut);
                            localArray.push(res.data[i].name);
                            localArray.push(res.data[i].descripcion);
                            localArray.push(res.data[i].modulo);
                            localArray.push(res.data[i].created_at);
                            localArray.push(res.data[i].device);
                            localArray.push(res.data[i]);
                            result.push(localArray);
                           }
                        this.setState({  loading: false, dataTable: result, logs: res.data, rowsSelected: [] });
                    })
                .catch(error => {
                   this.setState({ loading: false });
                    if (error.response) {
                        NotificationManager.error(error.response.data.message);
                      } else if (error.request) {
                        NotificationManager.error(error.request);
                      } else {
                        NotificationManager.error(error.message);
                      }
             });
            e.preventDefault();
	}
       
        
        render() {
            
            const { logs, loading,dataTable,maxDateNew, minDateNew,selectedDate } = this.state;

            const columns = [ 
                
                            {"name":"RUT",
                             "label":"RUT",
                                options: {
                                    filter: false,
                                    customHeadLabelRender: (columnMeta) => ( <b>{columnMeta.name}</b> ),
                                    customBodyRender: (value) => {
                                        return Format(value)
                                    }
                                    }},
                            {"name":"Nombre",
                             "label":"Nombre",
                                options: {
                                    filter: true,
                                    customBodyRender: (value) => {
                                        return (
                                        <Tooltip title={value}>
                                            <div className="texto-dotted-table-nombre">{value}</div>
                                        </Tooltip>);  
                                    },
                                    customHeadLabelRender: (columnMeta) => ( <b>{columnMeta.name}</b> ),
                                }},
                            {"name":"Descripción",
                             "label":"Descripción",
                                options: {
                                    filter: false,
                                    customBodyRender: (value) => {
                                        return (
                                        <Tooltip title={value}>
                                            <div className="texto-dotted-table-descripcion">{value}</div>
                                        </Tooltip>);  
                                    },
                                    customHeadLabelRender: (columnMeta) => ( <b>{columnMeta.name}</b> ),
                                    /*setCellProps: (cellValue, rowIndex, columnIndex) => {
                                        if(this.state.logs != null){
                                            return {
                                                className:  'text-white',
                                           };
                                        }
                                      }*/
                                }},
                            {"name":"Módulo",
                             "label":"Módulo",
                                options: {
                                    filter: true,
                                    customHeadLabelRender: (columnMeta) => ( <b>{columnMeta.name}</b> ),
                                }},
                            
                             {"name": "Fecha","label":"Fecha",
                                options: {
                                    filter:false,
                                    options: { sortDirection: 'desc' },
                                    customBodyRender: (value) => {
                                       return format(parseISO(value), 'dd-MM-yyyy H:mm');
                                    },
                                    customHeadLabelRender: (columnMeta) => ( <b>{columnMeta.name}</b> ),
                                    
                              },},
                           {"name": "Dispositivo","label":"Dispositivo",
                                options: {
                                   filter: true,
                                   display : true,
                                   customHeadLabelRender: (columnMeta) => ( <b>{columnMeta.name}</b> ),
                              }}
                            ];
                    const data = dataTable;
                    const options = {
			filterType: 'dropdown',
                        tableBodyMaxHeight:'500px',
                        tableBodyHeight:'500px',
                        setRowProps: (row, dataIndex, rowIndex) => {
                            if(row[9] == 'No disponible'){
                                return {
                                   style: { background: /*'rgb(255, 145, 110)'*/ '#CC5A65'},
                                };
                            }
                          },
                        downloadOptions : {filename: 'logs_vista.csv', separator: ';', filterOptions:{ useDisplayedRowsOnly : true }},
                        download: true,
                        print: false,
                        selectableRows : 'none',
                        textLabels: {
                            body: {
                              noMatch: "Lo sentimos, no se encotraron registros",
                              toolTip: "Ordenar",
                              columnHeaderTooltip: column => `Ordenar por ${column.label}`
                            },
                            pagination: {
                              next: "Siguiente",
                              previous: "Anterior",
                              rowsPerPage: "Filas por página:",
                              displayRows: "de",
                            },
                            toolbar: {
                              search: "Buscar",
                              downloadCsv: "Descarga vista CSV",
                              //print: "Print",
                              viewColumns: "Ver Columnas",
                              filterTable: "Filtrar",
                            },
                            filter: {
                              all: "Todas",
                              title: "FILTRAR",
                              reset: "REINICIAR",
                            },
                            viewColumns: {
                              title: "Mostrar Columnas",
                              titleAria: "Mostrar/Ocultar Columnas",
                            }
                          },
                         /* sortOrder: {
                                name: 'Fecha',
                                direction: 'desc',
                            },*/
                            /*onRowsDelete: (rowsDeleted) => {
                                let documents = this.state.documents;
                                let idsToDelete = rowsDeleted.data.map (item => item.dataIndex);
                                let obj = [];
                                for(let i=0;i<idsToDelete.length;i++){
                                    obj.push(documents[idsToDelete[i]].id)
                                }
      
                                this.refs.deleteManyConfirmationDialog.open();
                                this.setState({ allSelected: obj });
                    
                                return false;
                            },*/
                            rowsSelected: this.state.rowsSelected,
                            onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
                              this.setState({ rowsSelected: rowsSelected });
                            },
                           /* customToolbar: (selectedRows, displayData, setSelectedRows) => {
 
                                 const handleClickDownload = (e) => {
                                     e.preventDefault();
                                    let ahora = Date.now();
                                    var month = (this.state.selectedDate.getMonth() + 1).toString().padStart(2, "0"); 
                                    var year = this.state.selectedDate.getUTCFullYear();
                                    this.setState({  loading: true});
                                    axios.get(`/logs/download/`+month+'-'+year)
                                        .then(
                                            (res) => {
                                                let csvContent = "data:text/csv;charset=utf-8,";
                                                
                                                if(res.data.length > 0){
                                        
                                                    csvContent += "Inicio;Instalacion;Zona;Nombre;Rut;Turno;Motivo;Hora_Extra;Comentario;Fin;Responsable\r\n";

                                                    res.data.forEach(function(value, index) {
                                                        csvContent += value.fecha_in+";";
                                                        csvContent += value.instalacion+";";
                                                        csvContent += value.zona+";";
                                                        csvContent += value.name+";";
                                                        csvContent += value.rut+";";
                                                        csvContent += value.turno+";";
                                                        csvContent += value.motivo+";";
                                                        csvContent += value.hora_extra+";";
                                                        csvContent += value.comentario+";";
                                                        csvContent += value.fecha_fin+";";
                                                        if(value.admin != ""){
                                                            csvContent += value.admin+" ("+value.admin_rut+"-"+value.admin_zona+");\r\n";
                                                        }else {
                                                            csvContent += "-;\r\n";
                                                        }
                                                    });
                                                    
                                                    let encodedUri = encodeURI(csvContent);
                                                    let link = document.createElement("a");
                                                    link.setAttribute("href", encodedUri);
                                                    link.setAttribute("download", "logs_completo_"+month+'-'+year+"_"+ahora+".csv");
                                                    document.body.appendChild(link);
                                                    link.click();
                                                } else {
                                                    NotificationManager.error("No hay registros con la información solicitada");
                                                }
    
                                                this.setState({  loading: false});
                                                return true;
                                            })
                                        .catch(error => {
                                           this.setState({ loading: false });
                                            if (error.response) {
                                                NotificationManager.error(error.response.data.message);
                                              } else if (error.request) {
                                                NotificationManager.error(error.request);
                                              } else {
                                                NotificationManager.error(error.message);
                                              }
                                     });
                                    e.preventDefault();
                                 };

                                return (
                                        <Fragment>
                                                <Tooltip title="Descarga completa CSV">
                                                    <IconButton onClick={(e) => handleClickDownload(e)}  aria-label="Descarga completa CSV">
                                                      <span edge="start" aria-label="Descargar completa CSV" className="material-icons">download</span>
                                                    </IconButton>
                                                </Tooltip>
                                        </Fragment>
                                        )
                         },*/
   
                    };
     
     
            return ( <div className="data-table-wrapper">            
                <Helmet>
                        <title>{process.env.MIX_APP_NAME} | Logs de Sistema</title>
                        <meta name="description" content="Logs de Sistema" />
                </Helmet>
                <PageTitleBar
                        title="Logs de Sistema"
                        match={this.props.match}
                />
                <RctCollapsibleCard  fullBlock>
                    <div className="d-flex justify-content-between py-20 px-10 border-bottom">
                        <div>
                            <a href="#" onClick={(e) => this.onReload(e)} className="btn-outline-default mr-10"><i className="ti-reload"></i></a>
                            <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
                                <DatePicker
                                        views={['year', 'month']}
                                        label="Selecciona periodo"
                                        autoOk
                                        showTabs={true}
                                        maxDate={maxDateNew}
                                        minDate={minDateNew}
                                        dateformat="MM-YYYY"
                                        value={selectedDate}
                                        onChange={this.handleDateChange}
                                        showmonthyearpicker="true"
                                        leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
                                        rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                    <MUIDataTable 
                            title={"Listado de Logs"}
                            data={data}
                            columns={columns}
                            options={options}
                    />
                    { loading &&
                            <RctSectionLoader />
                    }    
                </RctCollapsibleCard>
            </div>);
                                
        }
}