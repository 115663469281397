/**
 * Signin Firebase
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Link } from 'react-router-dom';
import { Form, FormGroup, Input } from 'reactstrap';
import LinearProgress from '@material-ui/core/LinearProgress';
import QueueAnim from 'rc-queue-anim';
import { Helmet } from "react-helmet";
import {validate as Validate, clean, format as Format} from 'rut.js';

// components
import {
   SessionSlider
} from 'Components/Widgets';

// app config
import AppConfig from 'Constants/AppConfig';

// redux action
import {
   signinUserInFirebase,
   signinUserWithFacebook,
   signinUserWithGoogle,
   signinUserWithGithub,
   signinUserWithTwitter,
   toggleSidebarImage,
    setSidebarBgImageAction,
    miniSidebarAction,
    darkModeAction,
    boxLayoutAction,
    rtlLayoutAction,
    changeThemeColor,
    toggleDarkSidebar
} from 'Actions';

//Auth File
import Auth from '../Auth/Auth';
import { Fab } from '@material-ui/core';
import ThemeOptions from 'Components/ThemeOptions/ThemeOptions';
import RctSectionLoader from 'Components/RctSectionLoader/RctSectionLoader';

const auth = new Auth();

class Signin extends Component {
    
    constructor(props) {
      
        super(props);
            this.state = {
                rut : '',
                password: ''
            };
            
            this.props.darkModeAction(true);
        }
        
        componentWillMount(){
            const { location, history} = this.props;
            
            if(localStorage.getItem("id") > 0){
                axios.get('/checkloggin', {}).then((response) => {
                  if(response.data && response.data.check == true){
                      history.push('/');
                  }
                }, (error) => {
                    console.log(error);
                });
            } else {
                
                axios.get('/checkloggin', {}).then((response) => {
                  if(response.data && response.data.check == true){
                      axios.post('/logout', {})
                        .then(authUser => authUser /*history.push('/')*/)
                        .catch(error =>  error.response);
                  }
                }, (error) => {
                    console.log(error);
                });
               
            }
        }  

	/**
	 * On User Login
	 */
   onUserLogin() {
      //if (this.state.email !== '' && this.state.password !== '') {
         this.props.signinUserInFirebase(this.state, this.props.history);
      //}
   }
   
	/**
	 * On User Sign Up
	 */
   onUserSignUp() {
      this.props.history.push('/signup');
   }

  onBlurRut = (rut, name) => 
    {   
        if (rut != '') {
            if (rut.length > 3) {
               // validation.setFieldValue("rut", Format(rut));
                this.setState({ rut: Format(rut) });
              };
            }
        };
    
    onFocusRut = (rut, name) => 
    {  
        if (rut != ''){
            //validation.setFieldValue("rut", clean(rut));
            
            this.setState({ rut: clean(rut) });
            
        }
    };

   //Auth0 Login
   loginAuth0() {
      auth.login();
   }

   render() {
      const { rut, password } = this.state;
      const { loading } = this.props; 
      return (
         <QueueAnim type="bottom" duration={2000}>
            <Helmet>
                    <title>{process.env.MIX_APP_NAME} | Ingreso</title>
                    <meta name="description" content="Ingreso" />
            </Helmet>
            <div className="rct-session-wrapper" key="1">
               <AppBar position="static" className="session-header">
                  <Toolbar>
                     <div className="container">
                        <div className="d-flex justify-content-center">
                           <div className="session-logo">
                              <Link to="/">
                                 <img src={require('Assets/img/benu.png').default}  className="img-fluid" width="110" height="35" />
                              </Link>
                           </div>
                        </div>
                     </div>
                  </Toolbar>
               </AppBar>
               <div className="session-inner-wrapper">
               <div className="d-flex justify-content-center">
                  <div className="container">
                     <div className="d-flex justify-content-center">
                        <div className="col-sm-7 col-md-7 col-lg-8">
                           <div className="session-body text-center">
                               { loading &&
                                <RctSectionLoader />
                                }
                              <div className="session-head mb-30">
                                 <h2 className="font-weight-bold">{AppConfig.brandName} - Ingreso</h2>
                                 <p className="mb-0"></p>
                              </div>
                              <Form>
                                 <FormGroup className="has-wrapper">
                                    <Input
                                       type="text"
                                       value={rut}
                                       name="user-rut"
                                       id="user-rut"
                                       onFocus={(e) => this.onFocusRut(e.target.value, e.target.name)}
                                       onBlur={(e) => {
                                          this.onBlurRut(e.target.value, e.target.name);
                                        }}
                                       className="has-input input-lg"
                                       placeholder="Ingrese rut"
                                       onChange={(event) => this.setState({ rut: event.target.value })}
                                    />
                                    <span className="has-icon"><i className="ti-user"></i></span>
                                 </FormGroup>
                                 <FormGroup className="has-wrapper">
                                    <Input
                                       value={password}
                                       type="Password"
                                       name="user-pwd"
                                       id="pwd"
                                       className="has-input input-lg"
                                       placeholder="Contraseña"
                                       onChange={(event) => this.setState({ password: event.target.value })}
                                    />
                                    <span className="has-icon"><i className="ti-lock"></i></span>
                                 </FormGroup>
                                 <FormGroup className="mb-15">
                                    <Button
                                       color="primary"
                                       className="btn-block text-white w-100"
                                       variant="contained"
                                       size="large"
                                       onClick={() => this.onUserLogin()}
                                    >
                                       Iniciar Sesión
                            			</Button>
                                 </FormGroup>
                                 
                              </Form>
                             
                              <Button component={Link} to="/session/forgot-password" className="btn-info text-left pull-left btn-link text-white text-small">Recuperar contraseña</Button>
                                      
                           </div>
                        </div>
                        </div>
                          
                     </div>
                  </div>
               </div>
            </div>
            <ThemeOptions />
         </QueueAnim>
      );
   }
}

// map state to props
const mapStateToProps = ({ authUser }) => {
   const { user, loading } = authUser;
   return { user, loading }
}

export default connect(mapStateToProps, {
   signinUserInFirebase,
   signinUserWithFacebook,
   signinUserWithGoogle,
   signinUserWithGithub,
   signinUserWithTwitter,
   darkModeAction
})(Signin);
