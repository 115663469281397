/**
 * Todo App Data
 * Used To Describe Task Tyoe
 */
export default [
    {
        name: 'module.frontend',
        value: 1
    },
    {
        name: 'module.backend',
        value: 2
    },
    {
        name: 'module.api',
        value: 3
    },
    {
        name: 'module.issue',
        value: 4
    }
];
