/**
 * Dashboard
 */

import React, { Component, Fragment } from 'react'
import { Alert } from 'reactstrap';
import { Helmet } from "react-helmet";
import { Redirect, Route, Switch, Link } from 'react-router-dom';
import { Form, FormGroup, Input } from 'reactstrap';
import FormControl from '@material-ui/core/FormControl';
import MatButton from '@material-ui/core/Button';
// intl messages
import IntlMessages from 'Util/IntlMessages';
import Button from '@material-ui/core/Button';
import { format, parseISO, addHours} from 'date-fns';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { NotificationManager } from 'react-notifications';
// app config
import { connect } from 'react-redux';
// page title bar
import PageTitleBar from 'Components/PageTitleBar/PageTitleBar';

// rct collapsible card
import RctCollapsibleCard from 'Components/RctCollapsibleCard/RctCollapsibleCard';
// rct section loader
import RctSectionLoader from 'Components/RctSectionLoader/RctSectionLoader';
import { RctCard, RctCardContent } from 'Components/RctCard';
import { DateTimePicker, DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { es } from "date-fns/locale";
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import esLocale from "date-fns/locale/es";

import {
	VisitorAreaChartWidget,
	SalesAreaChartWidget,
	OrdersAreaChartWidget,
	RecentOrdersWidget,
	SupportRequest,
	Notifications,
	TopSellingWidget,
	OverallTrafficStatusWidget,
	ProductReportsWidget,
	OnlineVisitorsWidget,
	TodayOrdersStatsWidget,
	BookingInfo,
	NewOrderCountdown,
	FollowersWidget,
	Notes
} from "Components/Widgets";

// widgets data
import {
	visitorsData,
	salesData,
	ordersData,
	topSellingProducts,
	trafficStatus,
} from './data';

import axios from "axios";

import { Bar, Line } from 'react-chartjs-2';
import ChartConfig from 'Constants/chart-config';
import { Badge } from 'reactstrap';
import MatBadge from '@material-ui/core/Badge';

class Dashboard extends Component {
        
        constructor(props) {
      
        super(props);
            this.state = {
              ready: true,
              visible: true,
              loading: true,
              loadingGrafico2 : true,
              selectedDate: new Date(),
              last_activity: '',
              activeEnex : '',
              activeEsmax : '',
              activeEnex2 : '',
              activeEsmax2 : 'line-through',
              dataLineActivosEnex:'',
             dataLineActivosEnex2:'',
             totalEnex2: 0,
             totalEnex: 0,
             totalEsmax2: 0,
             totalEsmax: 0,
             dataLineDiasEnex : 0,
              maxDate : new Date(),
              minDate: new Date(2024, 8, new Date().getDate()),
              myRol : ''
            };
            this.onDismiss = this.onDismiss.bind(this);
        };
        
        onDismiss() {
            this.setState({ visible: false });
         }
         
         cambiaData2(e, string){
             e.preventDefault();
             
             let dataLineNuevos = [];
             let dataLineDias = [];
             let dataLineActivos = [];

             let activeEnex2 = this.state.activeactiveEnex2;
             let activeEsmax2 = this.state.activeEsmax2;
             
              if (string == 'ENEX') {
                
                if(this.state.activeEnex2 === ''){
                    activeEnex2 = 'line-through';
                } else {
                    activeEnex2 = '';
                }
                
                activeEsmax2 = 'line-through';
    
            }else if(string == 'ESMAX'){
                 
                if(this.state.activeEsmax2 === ''){
                    activeEsmax2 = 'line-through';
                } else {
                    activeEsmax2 = '';
                }
                activeEnex2 = 'line-through';
            } 
  
            if(activeEnex2 == ''){
                dataLineNuevos = dataLineNuevos.concat(this.state.dataLineNuevosEnex);
                dataLineDias = dataLineDias.concat(this.state.dataLineDiasEnex);
                dataLineActivos = dataLineActivos.concat(this.state.dataLineActivosEnex);
            }
           
            if(activeEsmax2 == ''){
                dataLineNuevos = dataLineNuevos.concat(this.state.dataLineNuevosEsmax);
                dataLineDias = dataLineDias.concat(this.state.dataLineDiasEsmax);
                dataLineActivos = dataLineActivos.concat(this.state.dataLineActivosEsmax);
            }
  
            this.setState({ 
                dataLineNuevos:dataLineNuevos, dataLineDias:dataLineDias,
                dataLineActivos:dataLineActivos,  activeEnex2:activeEnex2, activeEsmax2:activeEsmax2
            });

         }
   
         componentWillMount() {

            const { location, history, darkMode} = this.props;
            
            axios.get('/checkloggin', {}).then((response) => {
              if(!response.data){
                  if (localStorage.getItem("user_id") !== null) {
                        localStorage.removeItem('user_id');
                        localStorage.removeItem('name');
                        localStorage.removeItem('email');
                        localStorage.removeItem('rol');
                        localStorage.removeItem('rut');
                        history.push('/signin');
                        NotificationManager.error('¡Se ha perdido la sesión!');
                    }
              } else {
                  this.setState({ myRol:response.data.rol});
                  this.Init();
              }
            }, (error) => {
                console.log(error);

            });

        }
        
        onrefreshGraph2 = (e) => {
             e.preventDefault();
             this.setState({ loadingGrafico2: true });
             
             const { selectedDate } = this.state;

            let month = (selectedDate.getMonth() + 1).toString().padStart(2, "0"); 
            let year = selectedDate.getUTCFullYear();

             axios.get('/checklist/graphic-checklist/'+year+'-'+month, {}).then((response) => {
                   if(response.data && response.data.dia){

                        let dataLineDiasEnex = [];
                        let dataLineActivosEnex = [];
                        let dataLineActivosEnex2 = [];        

                        for(let i=0;i<response.data.dia.length;i++){
                            
                            dataLineDiasEnex.push(response.data.dia[i]);
                            dataLineActivosEnex.push(response.data.activasEnex[i]);
                            dataLineActivosEnex2.push(response.data.activasEnex2[i]);
   
                        }
                        this.setState({ loadingGrafico2 : false,
                          
                            dataLineActivosEnex:dataLineActivosEnex,
                            dataLineActivosEnex2:dataLineActivosEnex2,
                            totalEnex2: response.data.totalEnex2,
                            totalEnex: response.data.totalEnex,
                            dataLineDiasEnex : dataLineDiasEnex
                        });
                   } else {
                       this.setState({ loadingGrafico2 : false});  
                   }
                 }, (error) => {
                     console.log(error);
                     this.setState({ loadingGrafico2 : false});
                 });
        }
        
        handleDateChange = (date) => {
            this.setState({ selectedDate: date, loadingGrafico2: true });
            var month = (date.getMonth() + 1).toString().padStart(2, "0"); 
            var year = date.getUTCFullYear();
   
            axios.get('/checklist/graphic-checklist/'+year+'-'+month, {}).then((response) => {
                if(response.data && response.data.dia){

                     let dataLineDiasEnex = [];
                     let dataLineActivosEnex = [];
                     let dataLineActivosEnex2 = [];        

                     for(let i=0;i<response.data.dia.length;i++){

                         dataLineDiasEnex.push(response.data.dia[i]);
                         dataLineActivosEnex.push(response.data.activasEnex[i]);
                         dataLineActivosEnex2.push(response.data.activasEnex2[i]);

                     }
                     this.setState({ loadingGrafico2 : false,

                         dataLineActivosEnex:dataLineActivosEnex,
                         dataLineActivosEnex2:dataLineActivosEnex2,
                         totalEnex2: response.data.totalEnex2,
                         totalEnex: response.data.totalEnex,
                         dataLineDiasEnex : dataLineDiasEnex
                     });
                } else {
                    this.setState({ loadingGrafico2 : false});  
                }
              }, (error) => {
                  console.log(error);
                  this.setState({ loadingGrafico2 : false});
              });
        };
        
        Init(){
            
            this.setState({ loading:true });

            axios.get(`/users/last-activity`)
               .then(res => new Promise(resolve => setTimeout(() => resolve( 
             this.setState({ loading:false, last_activity : res.data[0].last_active_at })), 0)));

            if(localStorage.getItem("rol") == 'administrador' || (localStorage.getItem("user_id") == 1 || localStorage.getItem("user_id") == 9 || localStorage.getItem("user_id") == 910 || localStorage.getItem("user_id") == 13 || localStorage.getItem("user_id") == 8)){
     
                const { selectedDate } = this.state;

                let month = (selectedDate.getMonth() + 1).toString().padStart(2, "0"); 
                let year = selectedDate.getUTCFullYear();
                  
                 axios.get('/checklist/graphic-checklist/'+year+'-'+month, {}).then((response) => {
                   if(response.data && response.data.dia){

                        let dataLineDiasEnex = [];
                        let dataLineActivosEnex = [];
                        let dataLineActivosEnex2 = [];        

                        for(let i=0;i<response.data.dia.length;i++){
                            
                            dataLineDiasEnex.push(response.data.dia[i]);
                            dataLineActivosEnex.push(response.data.activasEnex[i]);
                            dataLineActivosEnex2.push(response.data.activasEnex2[i]);
   
                        }
                        this.setState({ loadingGrafico2 : false,
                          
                            dataLineActivosEnex:dataLineActivosEnex,
                            dataLineActivosEnex2:dataLineActivosEnex2,
                            totalEnex2: response.data.totalEnex2,
                            totalEnex: response.data.totalEnex,
                            dataLineDiasEnex : dataLineDiasEnex
                        });
                   } else {
                       this.setState({ loadingGrafico2 : false});  
                   }
                 }, (error) => {
                     console.log(error);
                     this.setState({ loadingGrafico2 : false});
                 });
             }
        }
        
        

         componentDidMount(){

         }

	render() {
                const {visible, selectedDate, loading, last_activity, myRol, loadingGrafico2, maxDate, minDate, activeEsmax2, totalEsmax2, totalEsmax, totalEnex2, totalEnex, activeEnex2,  dataLineDiasEnex,
              dataLineDiasEsmax, dataLineNuevosEnex, dataLineActivosEnex, dataLineActivosEnex2} = this.state;
		const { match, darkMode } = this.props;
       
                const isDesktop = window.innerWidth > 768;
                
                const options = {
                
                maintainAspectRatio: true, 
                    responsive: true,
                    indexAxis: 'x',
                    plugins:{
                           legend: {
                               display:true,
                               position: "bottom",
                               title: { display: true, padding: 5 },
                               labels: {
                                   color: (darkMode === true ? 'white' : 'black'),
                                   generateLabels: (chart) => {
                                    const datasetLabels = chart.data.datasets.map((dataset, i) => ({
                                        text: i === 0 ? 'Combustible ('+totalEnex+')' : 'Lubricantes ('+totalEnex2+')', // Cambia los textos de las leyendas
                                        fillStyle: dataset.backgroundColor,
                                        strokeStyle: dataset.borderColor,
                                        hidden: !chart.isDatasetVisible(i),
                                        lineCap: 'round',
                                        lineDash: [],
                                        lineDashOffset: 0,
                                        lineJoin: 'round',
                                        pointStyle: 'rect',
                                        rotation: 0,
                                        fontColor: (darkMode === true ? 'white' : 'black'),
                                        index: i,
                                        datasetIndex: i
                                    }));
                                    return datasetLabels;
                                    }
                               },
                                onHover: (event) => {
                                    event.native.target.style.cursor = 'pointer';
                                },
                                onLeave: (event) => {
                                    event.native.target.style.cursor = 'default';
                                }
                           }
                },    
                scales: {
                            x: {
                                    grid: {
                                            color: ChartConfig.chartGridColor,
                                            display:false
                                    },
                                    ticks: {
                                            fontColor: ChartConfig.axesColor,
                                            color: (darkMode === true ? 'white' : 'black')
                                    }
                            },
                            y: {
                                    grid: {
                                            color: ChartConfig.chartGridColor,
                                            display:false
                                    },
                                    ticks: {
                                            fontColor: ChartConfig.axesColor,
                                            color: (darkMode === true ? 'white' : 'black')
                                    }
                            },
                            
                    }
            }


            const data  = {
              labels: dataLineDiasEnex,
              datasets: [
                {
                  label: 'Combustible',
                  data: dataLineActivosEnex,
                  borderColor: ChartConfig.color.primary,
                  backgroundColor: ChartConfig.color.primary,
                  stack: 'combined',
                  type: 'bar'
                },
                {
                  label: 'Lubricantes',
                  data: dataLineActivosEnex2,
                  borderColor: ChartConfig.color.warning,
                  backgroundColor: ChartConfig.color.warning,
                  stack: 'combined',
                  type: 'bar'
                }
              ]
            };
               
		return (
                        
			<div className="ecom-dashboard-wrapper">
                                {loading  && 
                                    <RctSectionLoader />
				}
				<Helmet>
					<title>{process.env.MIX_APP_NAME} | Dashboard</title>
					<meta name="description" content="Dashboard" />
				</Helmet>
				<PageTitleBar title={<IntlMessages id="sidebar.dashboard" />} match={match} />
                                {!loading &&
                                <Fragment>
                                <Alert className="d-flex align-items-center" color="success" isOpen={this.state.visible} toggle={this.onDismiss}>
                                <i className="zmdi zmdi-check check-circle"></i> Última actividad registrada: {format(addHours(parseISO(last_activity),1), 'dd/MM/yyyy')} a las {format(addHours(parseISO(last_activity),1), 'H:mm')}
                                </Alert>
                                
                                 <Accordion  className="mb-30 panel" defaultExpanded>
                                        <AccordionSummary expandIcon={<i className="zmdi zmdi-chevron-down"></i>} className="m-0 panel-heading">
                                                <h4>Bienvenido</h4>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                                <p>
                                                       Sistema de Checklist para Transbenumapu, si detecta o experimenta algún problema favor enviar un mail a pablo.cruz@aysentf.cl o nikosk8r@gmail.com, o contáctese 
                                                       al número +56 9 88306126
                                                </p>
                                        </AccordionDetails>
                                </Accordion>
                                
                                <div className="row">
					
				<RctCollapsibleCard
                                        customClasses=""
                                        colClasses="col-sm-12 col-md-4 col-lg-4 w-xs-full"
                                        heading='Enlaces Rápidos'
                                        fullBlock
                                     >
                                    <RctCardContent>
                                        <FormControl  fullWidth > 
                                            <FormGroup>
                                                <MatButton  component={Link} to="/app/checklist/new" variant="contained" className="btn btn-block btn-warning mr-10 mb-10 text-white w-100">Ingresar Checklist</MatButton>
                                           </FormGroup>
                                           {myRol == 'administrador' &&
                                           <FormGroup>
                                                <MatButton component={Link} to="/app/users/user-management" variant="contained" className="btn btn-block btn-secondary mr-10 mb-10 text-white w-100">Gestión de Usuarios</MatButton>
                                            </FormGroup>
                                            }
                                        </FormControl>
                                     </RctCardContent>
                                    </RctCollapsibleCard>
                                    {(localStorage.getItem("user_id") == 1 || localStorage.getItem("user_id") == 9 || localStorage.getItem("user_id") == 13 || localStorage.getItem("user_id") == 8 || localStorage.getItem("user_id") == 10) &&
                                        <RctCollapsibleCard
                                        customClasses=""
                                        cardStyle={ isDesktop ? {height: '400px'} : ''}
                                        colClasses="col-sm-12 col-md-8 col-lg-8 w-xs-full"
                                        heading={'Checklist por Area'}
                                        onrefresh={this.onrefreshGraph2}
                                        fullBlock
                                     >
                                     {loadingGrafico2 ?
                                     <RctSectionLoader />
                                       : <RctCardContent>
                                       <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                              }}>
                                               <div style={{
                                                display: 'flex',
                                                alignItems: 'left',
                                                justifyContent: 'center',
                                                marginRight:'65px'
                                              }}>
                                               <MuiPickersUtilsProvider  locale={es} utils={DateFnsUtils}>
                                                        <DatePicker
                                                                views={['year', 'month']}
                                                                label="Selecciona periodo"
                                                                autoOk
                                                                showTabs={true}
                                                                dateformat="MM-YYYY"
                                                                maxDate={maxDate}
                                                                minDate={minDate}
                                                                value={selectedDate}
                                                                onChange={this.handleDateChange}
                                                                showmonthyearpicker="true"
                                                                leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
                                                                rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
                                                        />
                                                    </MuiPickersUtilsProvider>  
                                                    </div>
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                              }}>
                                              
                                             <Badge className="mb-10 mr-10 mt-10" color={activeEnex2 === '' ? 'primary': 'secondary'} style={{ cursor: 'pointer', textDecoration: activeEnex2 }} >ENEX ({totalEnex2+totalEnex})</Badge>
                                          </div>
                                          </div>
                                        <FormControl   fullWidth >
                                            <Bar
                                                style={{ marginTop: '20px' }}
                                                type="line"
                                                width={160}
                                                height={35}
                                                options={options}
                                                data={data}
                                              />
                                        </FormControl>
                                        </RctCardContent> }
                                    </RctCollapsibleCard>
                                    }
                                </div>
                                </Fragment>    
                                         
                                }
				
                        </div>
                     
		);
              }   
}

const mapStateToProps = ({ settings }) => {
	return settings;
};

export default connect(mapStateToProps, {})(Dashboard);
